import { get } from "lodash";
import { dictionaryList } from "./language/index";
const defaultLanguage = "en"
const messages = dictionaryList[defaultLanguage].errors;

// global regex
const noHtmlRegex = /<\/?[^>]+(>|$)/g;
const onlyAlphbetRegex = /^[a-zA-Z ]*$/;
const numberOnly = /^\d+$/;
var phoneRegex = /^[6-9]\d{9}$/;
var passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{7,}$/;
let newPasswordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
const decRegex = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;



export const loginValidator = (values) => {
  let errors = {};
  console.log(values);

  if (!values.mobile_number) {
    errors.phone = messages.invalid;
  } else if (!phoneRegex.test(values.mobile_number)) {
    errors.phone = messages.phone;
  } else if (values.mobile_number.length < 4) {
    errors.phone = messages.phone;
  }

  if (!values.password) {
    errors.password = messages.invalid;
  }
  console.log(errors);

  return errors;
};



export const forgetValidator = (values) => {
  let errors = {};
  if (!values.mobile_number) {
    errors.phone = messages.invalid;
  } else if (!phoneRegex.test(values.mobile_number)) {
    errors.phone = messages.phone;
  } else if (values.mobile_number.length < 4) {
    errors.phone = messages.phone;
  }


  return errors;
};


export const completeProfileValidator = (values) => {
  let errors = {};

  console.log(values);

  if (!values.service) {
    errors.service = "Please select a service.";
  }
  if (!values.name) {
    errors.name = "Customer name is required.";
  }
  if (!values.whatspp_number) {
    errors.whatspp_number = "WhatsApp number is required.";
  } else if (!phoneRegex.test(values.whatspp_number)) {
    errors.whatspp_number = "Invalid WhatsApp number format.";
  } else if (values.whatspp_number.length < 10 || values.whatspp_number.length > 11) {
    errors.whatspp_number = "WhatsApp number must be 10 digits.";
  }
  if (!values.house_number) {
    errors.house_number = "House number is required.";
  }
  if (!values.requirements) {
    errors.requirements = "Please specify your requirements.";
  }
  if (!values.locality) {
    errors.locality = "Locality is required.";
  }
  if (!values.city) {
    errors.city = "City is required.";
  }
  if (!values.state) {
    errors.state = "State is required.";
  }
  // if (!values.country) {
  //   errors.country = "Country is required.";
  // }
  if (!values.pincode) {
    errors.pincode = "Pincode is required.";
  } else if (!/^\d{6}$/.test(values.pincode)) {
    errors.pincode = "Pincode must be a 6-digit number.";
  }
  if (!values.time_preference) {
    errors.time_preference = "Time preference is required.";
  }

  return errors;
}

export const CenterValidator = (values) => {
  console.log(values);
  let errors = {};
  if (!values.center_name) {
    errors.center_name = "Enter center name";
  }
  if (!values.user_name) {
    errors.user_name = "Enter user name";
  }
  // if (!values.email) {
  //   errors.email = "Enter email";
  // }
  if (!values.about) {
    errors.about = "Enter about";
  }
  if (!values.mobile_number) {
    errors.mobile_number = "Enter mobile name";
  }
  if (!values.primary_services) {
    errors.primary_services = "Select service";
  }
  // if (!values.secondary_services_id) {
  //   errors.secondary_services_id = "Select other service";
  // }
  if (!values.address_line) {
    errors.address_line = "Enter address";
  }
  if (!values.city) {
    errors.city = "Enter city";
  }
  if (!values.state) {
    errors.state = "Enter state";
  }
  if (!values.country) {
    errors.country = "Enter country";
  }
  if (!values.pincode) {
    errors.pincode = "Enter pincode";
  }
  // if (!values.additional_pincode) {
  //   errors.additional_pincode = "Enter additional pincode";
  // }
  if (!values.no_of_technicians) {
    errors.no_of_technicians = "Enter technician";
  }
  if (!values.shirt_size) {
    errors.shirt_size = "Enter T-Shirt Size";
  }


  console.log(errors);
  return errors;
};